.recharts-wrapper {
    cursor: crosshair !important;
}

.recharts-scatter-symbol {
    cursor: pointer;
}

.recharts-cartesian-axis-tick {
    user-select: none;
}