.card__shine {
    display: grid;
    overflow: hidden;
    z-index: 3;
    transform: translateZ(1px);
    background: transparent;
    mix-blend-mode: color-dodge;
    background-size: cover;
    background-position: center;
    opacity: 2;
}

.card__shine:before {
    grid-area: 1/1;
    transform: translateZ(1px);
}
.card__shine:after {
    grid-area: 1/1;
    transform: translateZ(1.2px);
}

.shiny {
    color: white;
    position: relative;
    border: none;
    border-radius: 10px;
    font-weight: bold;
    overflow: hidden;
    cursor: none;
}

.shiny::after {
    content: "";
    position: absolute;
    top: calc(var(--y, 0) * 1px - 50px);
    left: calc(var(--x, 0) * 1px - 50px);
    width: 130px;
    height: 130px;
    background: radial-gradient(white, #3984ff00 70%);

    opacity: 0;
    transition: opacity 0.2s;
}

.shiny:hover::after {
    opacity: 0.3;
}