@font-face {
  font-family: 'Inter';
  font-weight: 100;
  src: url('./fonts/Inter-Thin-slnt=0.ttf') format('truetype');
}

@font-face {
  font-family: 'Inter';
  font-weight: 200;
  src: url('./fonts/Inter-ExtraLight-slnt=0.ttf') format('truetype');
}

@font-face {
  font-family: 'Inter';
  font-weight: 300;
  src: url('./fonts/Inter-Light-slnt=0.ttf') format('truetype');
}

@font-face {
  font-family: 'Inter';
  font-weight: 400;
  src: url('./fonts/Inter-Regular-slnt=0.ttf') format('truetype');
}

@font-face {
  font-family: 'Inter';
  font-weight: 500;
  src: url('./fonts/Inter-Medium-slnt=0.ttf') format('truetype');
}

@font-face {
  font-family: 'Inter';
  font-weight: 600;
  src: url('./fonts/Inter-SemiBold-slnt=0.ttf') format('truetype');
}

@font-face {
  font-family: 'Inter';
  font-weight: 700;
  src: url('./fonts/Inter-Bold-slnt=0.ttf') format('truetype');
}

@font-face {
  font-family: 'Inter';
  font-weight: 800;
  src: url('./fonts/Inter-ExtraBold-slnt=0.ttf') format('truetype');
}

@font-face {
  font-family: 'Inter';
  font-weight: 900;
  src: url('./fonts/Inter-Black-slnt=0.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 100;
  src: url('./fonts/Poppins/Poppins-Thin.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 200;
  src: url('./fonts/Poppins/Poppins-ExtraLight.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 300;
  src: url('./fonts/Poppins/Poppins-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 400;
  src: url('./fonts/Poppins/Poppins-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 500;
  src: url('./fonts/Poppins/Poppins-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 600;
  src: url('./fonts/Poppins/Poppins-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 700;
  src: url('./fonts/Poppins/Poppins-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 800;
  src: url('./fonts/Poppins/Poppins-ExtraBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 900;
  src: url('./fonts/Poppins/Poppins-Black.ttf') format('truetype');
}

body {
  margin: 0;
  color: #292929;
  background-color: #e5e5e5;
  font-family: -apple-system, 'Poppins', BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.dim {
  box-shadow: 0 0 0 100vmax rgba(0, 0, 0, .3);
  z-index: 498;
}

.dim-associate {
  z-index: 499;
}

/* test - to remove */