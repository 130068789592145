@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  h1 {
    @apply text-3xl md:text-5xl my-6 font-semibold text-brand font-headers;
  }
  h2 {
    @apply text-2xl md:text-3xl my-6 font-semibold text-brand font-headers;
  }
  h3 {
    @apply text-2xl my-6 font-semibold font-sans;
  }
  h4 {
    @apply text-lg my-2 font-semibold font-sans;
  }
  @font-face {
    font-family: 'Inter';
    font-weight: 100;
    src: url('./fonts/Inter-Thin-slnt=0.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Inter';
    font-weight: 200;
    src: url('./fonts/Inter-ExtraLight-slnt=0.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Inter';
    font-weight: 300;
    src: url('./fonts/Inter-Light-slnt=0.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Inter';
    font-weight: 400;
    src: url('./fonts/Inter-Regular-slnt=0.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Inter';
    font-weight: 500;
    src: url('./fonts/Inter-Medium-slnt=0.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Inter';
    font-weight: 600;
    src: url('./fonts/Inter-SemiBold-slnt=0.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Inter';
    font-weight: 700;
    src: url('./fonts/Inter-Bold-slnt=0.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Inter';
    font-weight: 800;
    src: url('./fonts/Inter-ExtraBold-slnt=0.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Inter';
    font-weight: 900;
    src: url('./fonts/Inter-Black-slnt=0.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Poppins';
    font-weight: 100;
    src: url('./fonts/Poppins/Poppins-Thin.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Poppins';
    font-weight: 200;
    src: url('./fonts/Poppins/Poppins-ExtraLight.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Poppins';
    font-weight: 300;
    src: url('./fonts/Poppins/Poppins-Light.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Poppins';
    font-weight: 400;
    src: url('./fonts/Poppins/Poppins-Regular.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Poppins';
    font-weight: 500;
    src: url('./fonts/Poppins/Poppins-Medium.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Poppins';
    font-weight: 600;
    src: url('./fonts/Poppins/Poppins-SemiBold.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Poppins';
    font-weight: 700;
    src: url('./fonts/Poppins/Poppins-Bold.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Poppins';
    font-weight: 800;
    src: url('./fonts/Poppins/Poppins-ExtraBold.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Poppins';
    font-weight: 900;
    src: url('./fonts/Poppins/Poppins-Black.ttf') format('truetype');
  }
}

@layer utilities {
  .text-gradient {
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    -moz-appearance:textfield;
    margin: 0;
  }
  input[type="number"] {
    -webkit-appearance: none;
    -moz-appearance:textfield;
    margin: 0;
  }
  .transition-slider {
    transition: transform 500ms, opacity 500ms;
  }
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  background-color: #f2f2f2;
}

.no-scroll-bar {
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}
.no-scroll-bar::-webkit-scrollbar {
  display: none;  /* Safari and Chrome */
}

.react-datepicker-popper {
  z-index: 50 !important;
}